@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "poppins";
}
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  font-family: montserrat;
  /* background: linear-gradient(120deg, #2980b9, #8e44ad); */
  /* background: #fff3e9; */
  background: #fdf8f4;
}
.Login_error {
  color: #fe5266;
  margin-top: 16px;
}

.center .submitBtn {
  text-align: center;
  color: #ffffff;
  background: linear-gradient(106.72deg, #ff5266 -9.44%, #f24396 90.41%);
  border-radius: 59px;
  border: none;
  outline: none;
}

.center .submitBtn:disabled {
  text-align: center;
  color: #ffffff;
  background: gray;
  border-radius: 59px;
  border: none;
  outline: none;
}

.RichTextEditor__root___2QXK- {
  min-height: 300px;
}
/* .modal-content {
  margin: 0 auto;
  width: auto;
} */
