.Apps {
  margin: 1rem 6rem;
}

.Apps form {
  margin-top: 20px;
}
.jodit-status-bar {
  visibility: hidden;
}

.quickread_from .submitBtn {
  width: 10rem;
  text-align: center;
  color: #ffffff;
  background: linear-gradient(106.72deg, #ff5266 -9.44%, #f24396 90.41%);
  border-radius: 59px;
  border: none;
  outline: none;
}

.quickread_from .submitBtn:disabled {
  width: 10rem;
  text-align: center;
  color: #ffffff;
  background: gray;
  border-radius: 59px;
  border: none;
  outline: none;
}
.quickRead__previewButton {
  border-radius: 59px;
  border: none;
  outline: none;
  width: 10rem;
  padding: 14px 14px;
  text-align: center;
  background-color: #282828;
  color: #ffffff;
  margin-right: 1rem;
}
.quickRead__previewButton:hover {
  color: wheat;
}
