/* Image Upload Container */
/* .image-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

/* File Input Styles */
/* .file-input {
  display: none;
} */

/* Uploaded Image Styles */
/* .uploaded-image {
  margin-top: 20px;
  max-width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} */

/* Styling for the container */
.image-upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  /* min-height: 100vh; */
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Styling for the heading */
.image-upload-container h4 {
  margin-bottom: 10px;
}

/* Styling for the file input */
.file-input {
  display: none; /* Hide the actual file input */
}

/* Styling for the label of the file input */
.file-label {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

/* Optional: Style the label to mimic a button appearance */
.file-label {
  border: 2px solid #007bff;
  transition: background-color 0.3s ease;
}

/* Optional: Add hover and focus effects to the label */
.file-label:hover,
.file-input:focus + .file-label {
  background-color: #0056b3;
}

/* Optional: Add active effect to the label */
.file-input:active + .file-label {
  background-color: #004080;
}

/* Styling for the uploaded image */
.uploaded-image {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Styling for the submit button */
.btn {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn:hover {
  background-color: #0056b3;
}

.btn:active {
  background-color: #004080;
}

.dailyClass_h4 {
  margin: 2rem 0;
}
