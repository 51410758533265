.BlogCommentsDisplay {
  margin: 1rem 6rem;
}

.blog_container-img {
  display: flex;
  /* justify-content: space-around; */
  gap: 3rem;
  /* align-items: center; */
  margin-top: 3rem;
  flex-wrap: wrap;
}

.blog_image {
  max-width: 300px;
  max-height: 300px;
  width: auto;
  height: auto;
  object-fit: contain;
}
