.gpt3__navbar {
  /* color: aliceblue; */
  color: #111;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 6rem;
  gap: 3rem;
  /* background-color: black; */
  background-color: #fff3e9;
}

.gpt3__navbar-logo a {
  font-weight: 700;
  text-decoration: none;
  /* color: white; */
  color: black;
}

.gpt3-navbar_links ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  list-style: none;
  /* background-color: brown; */
}
.gpt3-navbar_links ul li a {
  /* font-family: "Manrope"; */
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  color: black;
}
.gpt3__navbar-logo img {
  width: 62.56px;
  height: 16.02px;
}
.primary-btn {
  width: 57px;
  height: 25px;
  /* font-family: "Manrope"; */
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  margin-left: 80px;
}
.secondary-btn {
  width: 152px;
  height: 58px;
  left: 1153px;
  top: 55px;
  padding: 8px 24px;
  background: #ff4820;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.main-nav-list {
  margin-bottom: 0px;
}

.gpt3-navbar_links ul li a {
  text-decoration: none;
}

.gpt3-navbar_links ul li a:hover {
  text-decoration: underline;
}

/* Dropdown Container */
.dropdown-container {
  position: relative;
  display: inline-block;
}

/* Dropdown Button */
.dropdown-button {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

/* Dropdown List */
.dropdown-list {
  position: absolute;
  top: 120%;
  left: 0;
  background-color: #f9f9f9;
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ddd;
  border-top: none;
  display: flex;
  flex-direction: column;
  min-width: 160px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

/* Dropdown List Item */
.dropdown-list li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

/* Hover effect on list items */
.dropdown-list li:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown list when it's open */
.dropdown-list.open {
  opacity: 1;
  visibility: visible;
}

/* new dropDown  */
.dropdown-toggle {
  white-space: nowrap;
}
.show {
  opacity: 1;
  transition: all 1.2s ease-in-out;
}
.dropdown-menu .show {
  display: block;
  background: #fff3e9;
  position: absolute;
  right: 70px;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate3d(0px, 29.3333px, 0px);
  text-decoration: none;
}

.dropdown-menu a {
  text-decoration: none;
}
