.ProgramTimingContainer .redTimings h6 {
  font-family: "Avenir LT Std" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #393a3b;
  margin-top: 16px;
  margin-top: 40px;
}

.studioTimingHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.studioTimingHeader a {
  text-decoration: none;
  color: black;
}

.studioTimingHeader p {
  cursor: pointer;
  font-family: "Avenir LT Std" !important;
  font-size: 14px;
}

.studioTimingHeader p:hover {
  text-decoration: underline;
}

.ProgramTimingContainer .timeSlotsContainer {
  width: 100%;
}

@media screen and (max-width: 900px) {
  .studioTimingModal .ProgramTimingContainer {
    min-width: 100% !important;
  }

  .studioTimingModal {
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .studioTimingModal .modal-dialog {
    width: 90% !important;
    max-width: 90% !important;
    position: relative;
  }
  .ProgramTimingContainer .studioTimingTabsContainer {
    width: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
  .ProgramTimingContainer .studioTimingTabsContainer::-webkit-scrollbar {
    display: none;
  }

  .ProgramTimingContainer .studioTimingTabsContainer .studioTimingTabs {
    width: 100%;
    overflow-x: scroll;
  }

  .ProgramTimingContainer
    .studioTimingTabsContainer
    .studioTimingTabs::-webkit-scrollbar {
    display: none;
  }

  .ProgramTimingContainer .studioTimingTabsContainer .studioTimingTabs {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }

  .ProgramTimingContainer
    .studioTimingTabsContainer
    .studioTimingTabs
    .studioTabContainer
    h4 {
    width: max-content;
  }
}

/*============================timing======================================= */
.timingModal .modal-dialog {
  width: 833px !important;
  max-width: 833px !important;
  position: relative;
}

.ProgramTimingContainer {
  width: 833px;
  background: #ffffff;
  border-radius: 25px;
  position: relative;
  padding: 32px;
  overflow: hidden;
}

.timingModal .popupDesign {
  position: absolute;
  top: 0px;
  right: -50px;
  z-index: 0;
}

.timingHeader {
  display: flex;
  justify-content: center;
  width: 100%;
}
.timingHeader h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #393a3b;
}

.studioTimingTabsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  position: relative;
  margin-top: 18px;
}

/* ============================Tabs================================ */
.studioTimingTabs {
  display: flex;
  align-items: center;
  background: #fdfdfd;
  border: 1px solid #eeeeee;
  border-radius: 72px;
  /* gap: 18px; */
  padding: 0px 25px;
}

.studioTimingTabs > * + * {
  margin-left: 18px;
}

.studioTimingTabs .studioTabContainer {
  padding: 10px;
  cursor: pointer;
}

.activeProgramTab {
  border-bottom: 4px solid
      linear-gradient(91.17deg, #ff416c -15.57%, #ff4b2b 109.28%),
    linear-gradient(0deg, #d9d9d9, #d9d9d9);
}

.studioTimingTabs .studioTabContainer h4 {
  font-family: "Avenir LT Std" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

/* =============================Dropdown=========================== */
.timezoneDropDown select {
  width: 230px;
  max-width: max-content;
  padding: 8px 50px 8px 20px;
  max-width: auto;
  font-family: "Avenir LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  background: #fafafa;
  border: 1px solid #ececec;
  border-radius: 5px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: ellipsis;
}

.locationSelect {
  position: relative;
}
.locationSelect select {
  width: 200px;
}

.locationSelect img {
  position: absolute;
  width: 24px;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.timingModal .modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .timingModal .modal-dialog {
    width: 100% !important;
    max-width: 100% !important;
    position: relative;
    margin: 0px !important;
  }

  .ProgramTimingContainer {
    width: 95%;
    background: #ffffff;
    border-radius: 25px;
    position: relative;
    padding: 21px;
    overflow: hidden;
  }

  .studioTimingTabsContainer {
    flex-direction: column;
    /* gap: 12px; */
    align-items: flex-start;
  }

  .studioTimingTabsContainer > * + * {
    margin-top: 12px;
  }

  .timingHeader h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: normal;
    text-align: center;
    color: #393a3b;
  }

  .timingModal .studioTimingTabsContainer .studioTimingTabs {
    width: max-content !important;
    overflow-x: scroll;
  }
}

/* @media screen and (max-width: 900px){
    .RespTiming{
        margin-top: 150px;
        width: 100%;
    }

    .goBackLink{
        display: flex;
        gap: 9px;
        margin-bottom: 28px;
        cursor: pointer;
    }
    .goBackLink span{
        text-decoration: underline;
        font-family: 'Avenir LT Std' !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #000000;
    }

    .ProgramTimingContainer{
        width: 100%;
        background: #FFFFFF;
        border-radius: 25px;
        position: relative;
        padding: 70px;
        overflow: hidden;
    }

    .timingHeader{
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }
    .timingHeader h2{
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #393A3B;
    }

    .studioTimingTabsContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        z-index: 10;
        position: relative;
        margin-top: 18px;
        gap: 24px;
    }






    .studioTimingTabs{
        display: flex;
        align-items: center;
        background: #FDFDFD;
        border: 1px solid #EEEEEE;
        border-radius: 72px;
        gap: 18px;
        padding: 0px 25px;
    }
    
    .studioTimingTabs .studioTabContainer{
        padding: 10px;
        cursor: pointer;
    }
    
    .activeProgramTab{
        border-bottom: 4px solid linear-gradient(91.17deg, #FF416C -15.57%, #FF4B2B 109.28%),
        linear-gradient(0deg, #D9D9D9, #D9D9D9);
    }
    
    .studioTimingTabs .studioTabContainer h4{
        font-family: 'Avenir LT Std' !important;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
    }

}

@media screen and (max-width: 600px){


    .ProgramTimingContainer{
        width: 100%;
        background: #FFFFFF;
        border-radius: 25px;
        position: relative;
        padding: 21px;
        overflow: hidden;
    }
} */

.OnlineLiveTimings {
  margin-top: 30px;
  padding: 0px 0px;
}

.OnlineLiveTimings h6 {
  font-family: "Avenir LT Std" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #393a3b;
  margin-top: 16px;
}

.timeSlotsContainer {
  display: flex;
  /* row-gap: 19px;
    column-gap: 9px; */
  width: 600px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.timeSlotsContainer .timeSlots {
  background: #fafafa;
  border: 1px solid #ececec;
  border-radius: 39px;
  padding: 6px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 6px;
}

.timeSlotsContainer .timeSlots span {
  font-family: "Avenir LT Std" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #393a3b;
}

.ladiesTime {
  background: linear-gradient(106.72deg, #ff5266 -9.44%, #f24396 90.41%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.OnlineLiveTimings .note {
  margin-top: 40px;
}

.OnlineLiveTimings .note h5 {
  font-family: "Avenir LT Std" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #393a3b;
}

.OnlineLiveTimings .note ul {
  margin-top: 9px;
}

.OnlineLiveTimings .note li {
  font-family: "Avenir LT Std" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #393a3b;
  margin-top: 9px;
}

@media screen and (max-width: 600px) {
  .OnlineLiveTimings {
    margin-top: 30px;
    padding: 0px 0px;
  }

  .timeSlotsContainer {
    width: 100%;
  }
}

.chooseStudio {
  display: flex;
  align-items: center;
  /* gap: 30px; */
  margin-top: 30px;
}

.chooseStudio > * + * {
  margin-left: 30px;
}

.StudioTimings h6 {
  font-family: "Avenir LT Std" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #393a3b;
  margin-top: 16px;
}

.chooseStudio label {
  font-family: "Avenir LT Std" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #393a3b;
}

.chooseStudio select {
  background: #fafafa;
  border: 1px solid #ececec;
  border-radius: 5px;
  padding: 8px 20px;
  width: 230px;
  max-width: 230px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.StudioTimings .redTimings h6 {
  margin-top: 40px;
}

.StudioTimings .timeSlotsContainer {
  width: 100%;
  display: flex;
}

.note {
  margin-top: 40px;
}

.note h5 {
  font-family: "Avenir LT Std" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #393a3b;
}

.note ul {
  margin-top: 9px;
}

.note li {
  font-family: "Avenir LT Std" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #393a3b;
  margin-top: 9px;
}
