.ay__BasicProgramDetails {
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  gap: 3rem;
  /* margin: 30px 40px; */
  padding-bottom: 100px;
}
.ay__BasicProgramDetails-containerCard {
  display: flex;
  /* justify-content: space-between; */
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap;
}
/* .ay__BasicProgramDetails-containerCard .ay__programDetailCard{

} */
.noMargin {
  margin: 0;
}
