.eventSubscription {
  max-width: 1200px;
  margin: 0 auto;
}
.eventSubscription_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
}
.eventSubscription_dateRange {
  display: flex;
  margin-top: 3rem;
  gap: 2rem;
  /* align-items: end;
  justify-content: flex-end; */
}
