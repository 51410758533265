.ay__programBatchesDisplay {
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 30px 100px;
  padding-bottom: 100px;
}

.ay__programBatchesDisplay-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ay__programBatchesDisplay-header span {
  font-weight: 600;
  font-size: 24px;
}

.ay__ay__programBatchesDisplay-containerCard {
  display: flex;
  /* justify-content: space-between; */
  gap: 3rem;
  align-items: center;
  flex-wrap: wrap;
}

/* card css */

.ay__programBatchesDisplay-containerCard {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  flex-wrap: wrap;
}
.ay__programBatchesCard {
  width: max-content;
  /* height: max-content; */
  /* background-color: #fff; */
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 3px 4px 8px #0000002e;
  position: relative;
  cursor: pointer;
}
.ay__programBatchesCard-img img {
  width: 300px;
  /* height: 500px; */
}
.ay__programBatchesCard-content {
  position: absolute;
  /* display: flex;
  justify-content: space-around;
  align-items: center; */
  padding: 10px;
  bottom: 0px;
  width: 100%;
  height: 150px;
  background-color: aliceblue;
}

.batchesSwitch {
  position: absolute;
  top: 30px;
  right: 26px;
  width: max-content;
}
