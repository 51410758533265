.formContainer {
  display: flex;
  width: 100%;
  gap: 2rem;
  justify-content: space-between;
}

.formContainer form {
  width: 50%;
}

.paymentDetails {
  width: 50%;
  height: 100%;
}
.paymentDetails .paymentBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.paymentDetails .paymentBtn button {
  padding: 8.5px 24px !important;
  background: linear-gradient(106.72deg, #ff5266 -9.44%, #f24396 90.41%);
  border-radius: 38px;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  border: none;
  outline: none;
}

.paymentDetails h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 160%;
  color: #000000;
  /* margin-top: 41px; */
}

.paymentDetailsContainer {
  width: 100%;
  background: #f9f9f9;
  border: 1px solid #ececec;
  border-radius: 5px;
  /* height: 237px; */
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  margin-top: 4px;
}

.paymentAmount {
  display: flex;
  justify-content: space-between;
}

.paymentDetailsContainer h4 {
  margin-top: 0px;
  /* font-family: "Avenir LT Std" !important; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #000000;
  opacity: 0.5;
}
.name_h4 {
  width: 200px;
  text-align: right;
}

.paymentDetailsDivider {
  width: 100%;
  border: 1px solid #bababa;
  margin: 26px 0px;
}

.paymentTotalAmount {
  display: flex;
  justify-content: space-between;
}

.paymentTotalAmount h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 160%;
  color: #ff4a33;
  opacity: 1;
}

.paymentGateWay {
  display: flex;
  margin-top: 2rem;
  /* align-items: center;
    justify-content: center; */
  gap: 1rem;
  width: 100%;
}

.paymentGateWay_card {
  background: #f9f9f9;
  width: 50%;
  padding: 20px;
}
.paymentGateWay_content h3 {
  text-align: center;
  margin-bottom: 2rem;
  /* color: red; */
}

.paymentGateWay_content select {
  padding: 8px;
  width: 100%;
  border: none;
  outline: none;
  margin-bottom: 20px;
}

/* .formContainer_update {
  margin-top: 2rem;
} */
.packages_details {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}
