.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 400px;
  border-radius: 10px;
}
.center h1 {
  text-align: center;
  border-bottom: 1px solid silver;
  /* padding: 20px 0px 20px 0px; */
  margin: 20px 0px;
}
.center form {
  padding: 0 40px;
  box-sizing: border-box;
}
form .txt-field {
  position: relative;
  border-bottom: 2px solid silver;
  margin: 30px 0;
}
.txt-field input {
  width: 100%;
  padding: 0 5px;
  height: 40px;
  font-size: 16px;
  border: none;
  /* background: none; */
  outline: none;
}

.pass {
  margin: -5px 0px 20px 5px;
  cursor: pointer;
}
.pass a {
  text-decoration: none;
}
input[type="submit"] {
  width: 100%;
  height: 50px;
  border: 1px solid;
  /* background: #2691d9; */
  background: silver;
  border-radius: 25px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  border: none;
  margin-top: 15px;
  margin-bottom: 70px;
}
.bttn {
  width: 10%;
  height: 30px;
  border: 1px solid;
  /* background: #2691d9; */
  /* background:silver; */
  border-radius: 15px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  border: none;
  margin-top: 23px;
  margin-bottom: 15px;
  position: absolute;
  top: 87%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.signup-link {
  text-align: center;
  margin: 20px 0px;
  font-size: 16px;
}
.signup-link a {
  text-decoration: none;
}
span {
  color: red;
}

.RichTextEditor__block___2Vs_D span {
  color: black;
}
