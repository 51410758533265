.userDetail {
    max-width: 400px;
    margin: 40px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    
  }

  form {
    margin-top: 40px;
  }
  
  form div {
    margin-bottom: 10px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="text"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .submitBtnUser {
    padding: 8px 20px;
    background-color: #007bff; 
    cursor: pointer;
    text-align: center;
  color: #ffffff;
  background: linear-gradient(106.72deg, #ff5266 -9.44%, #f24396 90.41%);
  border-radius: 59px;
  border: none;
  outline: none;
  }
  
  .submitBtnUser:hover {
    background-color: #0056b3;
  }
  
  .user-details {
    margin-top: 40px;
  }
  
  .error {
    color: red;
    margin-top: 20px;
  }
  