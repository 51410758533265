.tabs-container {
  margin: 4rem 6rem;
  display: flex;
  gap: 10px;
}

.tabs-container button {
  padding: 8px 24px;
  background-color: rgb(139, 139, 139);
  color: white;
  font-weight: 600;
  border: none;
  outline: none;
}

.tabs-container .activeBtn {
  background-color: black;
}
