/* .QuickReadsPopUp{
    width: 100%;
    height: 300px;
    background-color: antiquewhite;
} */
/* .QuickReadsModal .modal-dialog {
  width: 1110px !important;
  height: 609px !important;
  max-width: 1110px !important;
  border: none;
  background-color: white;
} */

.quickReadsPopupContainer {
  display: flex;
  margin-top: 3rem;
}

.QrImageSlider {
  max-width: 609px;
  height: 609px;
}

.QrImageSlider .swiper-slide {
  width: 609px !important;
}

.QrImageSlider .swiper-slide img {
  width: 100%;
}

.QrDetails {
  width: 100%;
  height: 609px;
  position: relative;
  /* overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;  */
}

.QRDismissIcon {
  position: sticky;
  top: 0px;
  width: 100%;
  height: 41px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
  background-color: white;
}

.QRDismissIcon img {
  cursor: pointer;
}

.QrDetails::-webkit-scrollbar {
  display: none;
}

.QRDetailsContainer {
  height: 498px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.QRDetailsContainer::-webkit-scrollbar {
  display: none;
}
.QrDetailsTitleDate {
  padding: 0px 20px;
}
.QrDetailsTitleDate h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #393a3b;
}

.QrDetailsTitleDate span {
  margin-top: 8px;
  font-family: "Avenir LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #393a3b;
}
.QrDescriptionContainer {
  padding: 0px 20px;
  /* height: 685px; */
  /* overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;  */
}
.QrDescriptionContainer span {
  font-family: "Avenir LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #393a3b;
  cursor: pointer;
}
.QrDescription {
  margin-top: 8px;
  font-family: "Avenir LT Std";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #393a3b;
  width: 100%;
  /* height: 71px; */
  overflow-y: hidden;
}

.writeCommentContainer {
  width: 100%;
  height: auto;
  position: sticky;
  top: 100%;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  padding: 12px 0px;
  border-top: 1px solid #ececec;
  background-color: white;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
}

.writeCommentContainer input {
  width: 400px;
  background: #fafafa;
  border: 1px solid #ececec;
  border-radius: 5px;
  padding: 9.5px 20px;
}

/* ======================Comments Section======================== */

.commentsParentcontainer {
  padding: 0px 20px;
}

.commentsParentcontainer h4 {
  font-family: "Avenir LT Std" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #393a3b;
}

.commentSection {
  width: 100%;
  padding: 8px 20px;
  border: 1px solid #ececec;
  margin-top: 24px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.commentSection h4 {
  font-weight: 700;
  color: #000000;
}
.commentSectionContainer {
  width: 100%;
  height: auto;
  border: 1px solid #ececec;
}
.commentSectionContainer .comments {
  border-bottom: 1px solid #ececec;
  padding: 8px 20px !important;
  display: flex;
  gap: 8px;
  align-items: flex-start;
}
.commentSectionContainer .comments .username {
  font-weight: 700;
  color: #000000;
  margin-bottom: 4px;
}
.userComment {
  width: 100%;
}
.userComment p {
  font-family: "Avenir LT Std" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #393a3b;
  margin-top: 8px;
}

@media screen and (max-width: 600px) {
}
