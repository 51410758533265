.ay__programDetailCard {
  width: max-content;
  /* height: max-content; */
  /* background-color: #fff; */
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 3px 4px 8px #0000002e;
  position: relative;
  cursor: pointer;
}
.ay__programDetailCard-img img {
  width: 300px;
  /* height: 500px; */
}
.ay__programDetailCard-content {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  bottom: 0px;
  width: 100%;
  height: 100px;
  background-color: aliceblue;
  /* gap: 3rem; */
}
.ay__programDetailCard-content_img img {
  width: 30px;
}
