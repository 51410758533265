.quickReadPreview__content {
  margin-top: 2rem;
  color: #fff;
  font-family: Avenir LT Std;
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 160%;
}

.quickReadPreview__title {
  margin-top: 2rem;
  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
  /* whitespace: nowrap; */
  font-family: Avenir LT Std;
  font-size: 20px;
  font-style: normal;
  font-weight: 750;
  line-height: normal;
}
.quickReadPreview__body {
  background: #1d2331;
  color: white;
  margin: 0 auto;
  width: 375px;
}
