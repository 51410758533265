.OurStudios {
  /* margin-top: 65px; */
  width: 100%;
  height: auto;
  background: #1b2024;
  position: relative;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 65px;
  /* color: black !important; */
}

.ourStudiosHeader {
  color: white;
  justify-content: center;
  align-items: center;
  margin-top: 65px;
  width: 709px;
}
.ourStudiosHeader h1 {
  text-align: center;
  font-weight: 700;
  font-size: 45px;
  line-height: 55px;
}
.ourStudiosHeader h4 {
  margin-top: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
}
/* ===============Swipper===================== */
.ourStudiosContainer {
  display: flex;
  gap: 4rem;
  width: 1055px;
  position: relative;
  margin-top: 20px;
}
.ourStudiosContainer .swiper-button img {
  position: absolute;
  top: 50%;
  cursor: pointer;
  z-index: 10;
}
.ourStudiosContainer .swiper-button .prevIcon {
  left: -50px;
}

.ourStudiosContainer .swiper-button .nextIcon {
  right: -50px;
}

/* ====================Slider Css================== */
/* .ourStudiosContainer{
    width: 100%;
    padding:0px 110px;
    height: max-content !important; 
    z-index: 90;
    position: relative;
} */

.ourStudiosContainer .studioCard {
  width: auto !important;
  height: auto !important;
}

.studioImgContainer {
  position: relative;
  width: max-content;
  cursor: pointer;
}
.studioImgGradient {
  position: absolute;
  top: 0px;
  width: 247px;
  height: 282px;
  /* background: linear-gradient(179.45deg, #000000 -4.68%, rgba(0, 0, 0, 0) 56.82%), url(IMG_6761.jpg); */
  border-radius: 10px;
}
.studioImgContainer img {
  width: 100%;
}
.studioImgContainer .studioImg {
  opacity: 0.4;
  width: 247px;
  height: 282px;
  object-fit: cover;
  border-radius: 10px;
}

.studioDetails {
  position: absolute;
  top: 32px;
  left: 20px;
  color: white;
  padding-right: 10px;
}
.studioDetails h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.studioDetails .address {
  display: flex;
  gap: 6px;
  /* align-items: center; */
  margin-top: 15px;
}

.studioDetails .address a {
  text-decoration: none;
}
.studioDetails .address img {
  width: 13px;
  height: 15px;
}
.studioDetails .address p {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}
.playIcon {
  position: absolute;
  bottom: 11px;
  right: 31px;
  cursor: pointer;
}

/* ================Gradients Css===================== */
.Gradients .blueGradient {
  position: absolute;
  top: 0px;
  right: 0px;
  user-select: none;
}

.Gradients .redGradient {
  position: absolute;
  bottom: 0px;
  left: 0px;
  user-select: none;
}

.Gradients .whiteGradient {
  width: 472px;
  height: 472px;
  left: 447px;
  top: 249px;
  user-select: none;
  background: rgba(251, 251, 251, 0.74);
  mix-blend-mode: overlay;
  filter: blur(72.5px);
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.respOurStudiosContainer {
  display: none;
}

@media screen and (max-width: 900px) {
  .ourStudiosContainer {
    width: 650px;
    position: relative;
    margin-top: 20px;
  }

  .studioImgContainer .studioImg {
    width: 210px !important;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
  }
  .studioImgGradient {
    position: absolute;
    top: 0px;
    width: 210px;
    height: 100%;
    /* background: linear-gradient(179.45deg, #000000 -4.68%, rgba(0, 0, 0, 0) 56.82%), url(IMG_6761.jpg); */
    border-radius: 10px;
  }

  .studioDetails h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
  .studioDetails .address {
    display: flex;
    gap: 6px;
    align-items: center;
    margin-top: 15px;
  }

  .ourStudiosContainer .swiper-button .prevIcon {
    left: -30px;
  }

  .ourStudiosContainer .swiper-button .nextIcon {
    right: -30px;
  }
}

@media screen and (max-width: 600px) {
  .OurStudios {
    margin-top: 20px;
  }

  .ourStudiosHeader {
    color: white;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
    width: 100%;
    padding: 0px 21px;
  }
  .ourStudiosHeader h1 {
    text-align: left;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
  }
  .ourStudiosHeader h4 {
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: left;
  }

  .ourStudiosContainer {
    display: none;
  }
  .respOurStudiosParentContainer {
    width: 100%;
    padding: 0px 0px 0px 21px;
    z-index: 10;
    margin-top: 20px;
  }

  .respOurStudiosContainer {
    display: flex;
    gap: 22px;
    overflow-x: scroll;
    padding-right: 21px;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .respOurStudiosContainer::-webkit-scrollbar {
    display: none;
  }

  .Gradients .whiteGradient {
    display: none;
  }
  .Gradients .blueGradient {
    width: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    user-select: none;
  }
  .Gradients .blueGradient img {
    width: 100%;
  }

  .Gradients .redGradient {
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    user-select: none;
  }
  .Gradients .redGradient img {
    width: 100%;
  }
}
