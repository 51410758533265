.ay__packageCard {
  border: 2px solid red;
  border-radius: 18px;
  padding: 24px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px auto;
}
.ay__packageCard_leftSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.ay__packageCard_leftSide p {
  margin: 0;
}
