.ay__subscriptionCard {
    border: 2px solid red;
    border-radius: 18px;
    padding: 24px 40px;
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
    margin: 50px auto;
    width: 100%;
  }
  .ay__subscriptionCard_leftSide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    width: 50%;
  }
  .ay__subscriptionCard_center{
    width: 30%;
    margin-top: 5rem;
  }

  .ay__subscriptionCard_RightSide{
    width: 20%;
    text-align: end;
  }

  .ay__subscriptionCard_leftSide p {
    margin: 0;
  }
  