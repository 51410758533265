.qnaDetails {
  margin: 2rem 6rem;
}
.qnaDetails_title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 3rem;
}
.qnaDetails_Data {
  padding: 2rem;
  background-color: antiquewhite;
  border: 1px solid black;
  border-radius: 2rem;
  margin-bottom: 2rem;
}
.qnaDetails_comments {
  /* max-width: 400px; */
  margin-bottom: 2rem;
  /* border: 1px solid grey; */
}
