.qnaParentContainer {
  margin: 1rem 6rem;
}

.breadcrumbcontainer span {
  font-weight: 600;
  font-size: 24px;
}
.qna_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
}

.breadcrumb-nav1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* table {
  border-collapse: collapse;
  text-align: center;
} */

/* tableStyles.css */

/* Style for the table */
.table-container {
  width: 100%;
  border-collapse: collapse;
}

/* Style for table header */
.table-header {
  background-color: #f2f2f2;
  font-weight: bold;
}

/* Style for table rows */
.table-row:nth-child(even) {
  background-color: #f2f2f2;
  padding: 20px !important;
}

.table-row:nth-child(odd) {
  background-color: #ffffff;
  padding: 20px !important;
}

/* Style for table cells */
.table-cell {
  padding: 10px;
  border: 1px solid #dddddd;
  text-align: left;
}

/* CSS for the FAQ search input container
.faqSearchInput {
  position: relative;
  display: inline-block;
}

/* CSS for the search input */
.faqSearchInput input[type="text"] {
  padding: 8px 32px 8px 12px;
  border: 1px solid #ccc;
  border-radius: 25px;
  outline: none;
  transition: all 0.3s ease-in-out;
  width: 220px;
}

/* CSS for the search icon */
.faqSearchInput .searchIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

/* CSS for the search icon hover effect */
.faqSearchInput .searchIcon:hover {
  transform: translateY(-50%) scale(1.2);
}

/* new searchBar */
.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}

.search-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  flex-grow: 1;
  font-size: 16px;
}

.search-button {
  background-color: #007bff;
  border: none;
  border-radius: 0 5px 5px 0;
  padding: 10px;
  cursor: pointer;
}

.search-icon {
  width: 20px;
  height: 20px;
  fill: #fff;
}
