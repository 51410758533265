.ay__programPackages {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: antiquewhite; */
  gap: 4rem;
  margin: 10px 30px;
}
.ay__programPackages-priceCards {
  display: flex;
  align-items: center;
  /* margin: 30px 20px; */
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}
.ay__programPackages-priceCard {
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  justify-content: center;
  border: 2px solid gray;
  padding: 2rem;
  border-radius: 16px;
  height: 15rem;
  width: 18rem;
  position: relative;
}
.ay__programPackages-priceCards-red {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.program-price-container2 {
  width: max-content;
  cursor: pointer;
  background: #fdfdfd;
  border: 1px solid #eeeeee;
  border-radius: 72px;
  display: flex;
  justify-content: space-between;
  gap: 18px;
  padding: 0px 12px;
}

.ay__programPackages-priceCard button {
  padding: 8px 10px;
  border-radius: 18px;
  border: none;
  transition-property: border;
  transition-duration: 0.2s;
}
.ay__programPackages-priceCard button:hover {
  border: 1px solid grey;
}

.activeProgramTab {
  border-bottom: 4px solid #ff416c;
}

/* animation */
.ay__programPackages-priceCard {
  transition: max-height 0.3s ease;
}

.activeCard {
  max-height: 1000px;
}

.inputAnimation {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.activeCard .inputAnimation {
  opacity: 1;
  transform: translateY(0);
}
.create_Variant button {
  padding: 10px;
  border-radius: 18px;
  background: linear-gradient(106.72deg, #ff5266 -9.44%, #f24396 90.41%);
  border: none;
  color: white;
}

.ay__programPackages-priceCard_delete {
  position: absolute;
  top: 4px;
  right: 1rem;
}
